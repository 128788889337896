<template>
  <div>
    <div class="content-item">
      <div class="content-item__method" :data-methodType="method">{{method}}</div>
      <h3 v-if="title">{{title}}</h3>
      <p v-if="endpointPath">
        <b>{{ endpointPath }}</b>
        <bb-icon @click="copyLink(endpointPath)" name="ui-content-copy" class="content-item__copy"/>
      </p>
      <span class="hr"></span>
      <p>{{summary}}</p>
    </div>

    <div v-if="hasDescriptions">
      <descriptions-section/>
    </div>

    <div
      class="content-item"
      v-if="exampleRequest"
      style="margin-top: 30px;"
    >
      <h3>Request example template
        <span
          @click="copyJSON(getExampleRequestData)"
          class="content-item__copy-wrapper"
        >
          <bb-icon
            name="ui-content-copy"
            class="content-item__copy"
          />
          <small>{{copyState}}</small>
        </span>
      </h3>
      <div class="content-item__request">
        <vue-json-pretty
          :data="getExampleRequestData"
          :deep=1
        />
      </div>
    </div>

    <div class="content-item">
      <h4>Parameters</h4>
      <bb-table
        :columns="parameterTableColumns"
        :data="parameterTableData"
        color="gray"
      />
    </div>

    <div class="content-item">
      <h4 v-if="responses">Responses</h4>
      <ul>
        <li
          v-for="(item, index) in responses"
          :key="index"
          class="content-item__list-item"
          :class="{hasChildren: item.properties}"
        >
          <div
            class="content-item__list-title"
            @click="activeItemId === index ? activeItemId = null : activeItemId = index"
          >
            <b :data-colorCode="item.status">{{item.status}}</b>
            <b>{{item.description}}</b>
          </div>
          <div
            :class="[{isInactive: activeItemId != index}]"
          >
            <bb-table
              v-if="item.properties"
              style="margin: 30px 0;"
              color="gray"
              :columns="responseTableColumns"
              :data="item.properties"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/';
import LvNewApplicationRequestTemplate from '@/contents/LvNewApplicationRequestTemplate.json';
import LtNewApplicationRequestTemplate from '@/contents/LtNewApplicationRequestTemplate.json';
import EeNewApplicationRequestTemplate from '@/contents/EeNewApplicationRequestTemplate.json';
import EeNewLightApplicationRequestTemplate from '@/contents/EeNewLightApplicationRequestTemplate.json';
import { BbIcon, BbTable } from '@bigbank/interface-components';
import VueJsonPretty from 'vue-json-pretty';
import DescriptionsSection from '@/components/DescriptionsSection.vue';

export default {
  name: 'content-item',
  components: {
    BbIcon,
    BbTable,
    VueJsonPretty,
    DescriptionsSection,
  },
  props: {
    title: {
      type: String,
    },
    endpointPath: {
      type: String,
    },
    link: {
      type: String,
    },
    summary: {
      type: String,
    },
    description: {
      type: String,
    },
    responses: {
      type: Array,
    },
    parameters: {
      type: Array,
    },
    method: {
      type: String,
    },
    exampleRequest: {
      type: String,
    },
    hasDescriptions: {
      type: Boolean,
    },
  },
  data() {
    return {
      parameterTableColumns: [
        {
          key: 'name',
          label: 'Parameter name',
        },
        {
          key: 'context',
          label: 'Required|Type|In',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'acceptedValues',
          label: 'Accepted values',
        },
      ],
      parameterTableData: this.parseParameters(),
      responseTableColumns: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'format',
          label: 'Format',
        },
        {
          key: 'example',
          label: 'Example values',
        },
      ],
      activeItemId: null,
      copyState: 'COPY',
    };
  },
  computed: {
    getExampleRequestData() {
      const domain = window.config.DOMAIN;
      if (domain === 'LT') {
        return LtNewApplicationRequestTemplate;
      }
      if (domain === 'LV') {
        return LvNewApplicationRequestTemplate;
      }
      if (domain === 'EE') {
        return this.exampleRequest === 'EeNewLightApplicationRequestTemplate'
          ? EeNewLightApplicationRequestTemplate
          : EeNewApplicationRequestTemplate;
      }
      return null;
    },
  },
  methods: {
    copyLink(rowValue) {
      const baseLink = 'https://partner-api-lv-pmu-dev.dca.origin.big.local';
      navigator.clipboard.writeText(baseLink + rowValue);
    },
    copyJSON(name) {
      const formattedSchema = JSON.stringify(name);
      navigator.clipboard.writeText(formattedSchema);
      this.copyState = 'COPIED TO CLIPBOARD!';
      setTimeout(() => {
        this.copyState = 'COPY';
      }, 3000);
    },
    parseParameters() {
      return this.parameters.map((item) => ({
        ...item,
        context: `${item.required ?? 'false'} | ${item.type} ${item.in ? `| ${item.in}` : ''}`,
      }));
    },
  },
};
</script>

<style lang="scss">
  .content-item {
    & + & {
      margin-top: 30px;
    }
  }
  .content-item__copy {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    color: #c0c0c0;
    margin-left: 20px;
    transition: color ease-out 100ms;
    position: relative;
    top: 3px;

    &:hover {
      cursor: pointer;
      color: #687d92;
    }
  }

  p {
    margin-top: 10px;
  }

  ul {
    margin-top: 30px;
  }

  .content-item__list-item {
    & + & {
      margin-top: 10px;
    }
  }

  .content-item__list-item.hasChildren {
    .content-item__list-title {
      cursor: pointer;

      &:hover {
        background-color: #e1e1e1;
      }
    }
  }

  [data-colorCode] {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    min-width: 50px;
    padding: 5px;
    margin-right: 10px;
    color: #fff;
  }

  [data-colorCode^='2'] {
    background-color: #6fb34b;
  }

  [data-colorCode^='4'] {
    background-color: #c2493c;
  }

  [data-colorCode^='5'] {
    background-color: #494b4d;
  }

  .isInactive {
    display: none;
  }

  .content-item__content-wrapper {
    margin-top: 10px;
  }

  .content-item__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 50px;
    word-break: break-all;

    & > div {
      padding: 3px 10px;
    }

    & > div:first-of-type {
      min-width: 200px;
      max-width: 200px;
      border-right: 2px solid gray;
    }
  }

  .content-item__method {
    font-size: 12px;
    padding: 3px 8px;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    width: min-content;
    margin-bottom: 5px;
  }

  [data-methodType] {
    background-color: #66a7ba;
  }

  [data-methodType='POST'] {
    background-color: #93ba66;
  }

  [data-methodType='PUT'] {
    background-color: #9071ad;
  }

  .content-item__parameter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 3px 0;
  }

  .content-item__parameter:nth-of-type(odd) {
    background-color: rgba(0,0,0,0.05)
  }

  .content-item__parameter-children {
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 0 0 100% !important;
    width: 100% !important;
  }

  .content-item__request {
    background-color: #3c3c3d;
    padding: 10px;
    border-radius: 10px;
  }

  .vjs-key {
    color: #c7c7c7;
  }

  .vjs-value-boolean {
    color: #f1ffa7;
  }

  .vjs-value-string {
    color: #64dace  ;
  }

  .vjs-value-number {
    color: #ff9634;
  }

  .vjs-tree-brackets {
    color: #a7a7a7;

    &:hover {
      color: #fff;
    }
  }

  .vjs-tree-node:hover {
      background-color: rgba(#fff,0.1);
  }

  .content-item__copy-wrapper {
    padding: 2px 10px;
    transition: background-color ease-out 100ms;

    &:hover {
      cursor: pointer;
      border-radius: 2px;
      background-color: rgba(0,0,0,0.05);
    }

    > small {
      margin-left: 5px;
    }

    > .content-item__copy {
      margin-left: 0;

      &:hover {
        color: #c0c0c0;
      }
    }
  }

</style>
