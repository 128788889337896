<template>
  <div class="content">
    <div class="content__heading">
      <h1>{{getTitle}}<small class="text--boxed">{{getVersion}}</small></h1>
      <p>{{getDescription}}</p>
    </div>
    <span class="hr"/>
    <div
      v-if="isLv"
      class="chapter"
      id="quickstart"
    >
      <h2>Quickstart guide</h2>
      <descriptions-section
        isExtended
      />
    </div>
    <content-item
      v-for="(section, index) in sections"
      :key="index"
      :id="section.id"
      class="chapter"
      :endpointPath="section.endpointPath"
      :title="section.title"
      :summary="section.description"
      :responses="section.responses"
      :parameters="section.parameters"
      :method="section.method"
      :exampleRequest="section.exampleRequest"
      :hasDescriptions="section.hasDescriptions"
    />
  </div>
</template>

<script>
import mainSchema from '@/contents/mainSchema';
import ContentItem from '@/components/ContentItem.vue';
import DescriptionsSection from '@/components/DescriptionsSection.vue';

export default {
  name: 'ContentsView',
  components: {
    ContentItem,
    DescriptionsSection,
  },
  data() {
    return {
      sections: [],
    };
  },
  created() {
    this.getSections();
  },
  computed: {
    isLv() {
      return window.config.DOMAIN === 'LV';
    },
    isEe() {
      return window.config.DOMAIN === 'EE';
    },
    getSchema() {
      return mainSchema()[window.config.DOMAIN];
    },
    getTitle() {
      return this.getSchema.title;
    },
    getDescription() {
      return this.getSchema.description;
    },
    getVersion() {
      return this.getSchema.version;
    },
  },
  methods: {
    getSections() {
      this.getSchema.groups.forEach((element) => {
        const endpoint = element.endpoints[0];
        return this.sections.push(
          {
            ...endpoint,
            id: endpoint.internalPath,
            endpointPath: endpoint.path,
            title: element.name,
          },
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
  h1 {
    margin: 0;
  }
  small {
    position: relative;
    top: -16px;
    margin-left: 15px;
  }
  .content {
    text-align: left;
    margin: 50px 50px 0 0;
    width: 100%;
    height: 100%;
    padding-left: 350px;
    padding-right: 80px;
  }

  .content__heading {
    margin-bottom: 30px;
    position: relative;
  }
  .content__heading > * {
    margin-bottom: 8px;
  }

  .chapter {
    padding-top: 30px;
    & + & {
      padding-top: 80px;
    }
  }
</style>
