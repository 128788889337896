import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import ContentsView from '@/views/ContentsView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    component: ContentsView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to && to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 80 },
        behavior: 'smooth',
      };
    } if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
