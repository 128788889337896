<template>
  <div>
    <p>
      Before sending your first application you need a token. For this turn to
      <a href="#authentication"><u>
         Retrieve authentication token</u>
      </a>
    </p>
    <br/>
    <h3>Possible flows for integration</h3>
    <br/>
    <div v-if="isExtended" class="descriptions__table">
      <div class="descriptions__table-single">
        <div class="descriptions__table-header">
          <h4>Hosted Flow</h4>
        </div>
        <div>
          The easiest way to integrate with Bigbank's Partner API is to use the hosted flow.
          You need to forward the customer to Bigbank's hosted webflow with the request URL
          described below. After the customer has completed the hosted flow in
          Bigbank's environment, the customer will be redirected back to your site.
        </div>
        <div>
          <b>Method URL</b>
          <p class="code-block">Integration: {{ getIntegrationUrl }}</p>
          <p class="code-block">Production: {{ getProductionUrl }}</p>
        </div>
        <div>
          <b>Parameters</b>
          <bb-table
            color="gray"
            disableTableHead
            dashed
            striped
            :data="hostedflowData"
          />
        </div>
        <div>
          <b>Sample forward request:</b>
          <p class="code-block" style="word-break: break-all;">{{ forwardRequestSample }}</p>
        </div>
        <div>
          <b>When the customer is redirected back to your site from Bigbank's "Hosted flow"</b>
          <p>
            When the customer is redirected back to your site after completing Bigbank
            application process, it is your responsibility to validate the transaction status.
            For that you should use the
            <a href="#query-application">query application status endpoint</a>
            with the reference_id you set in forwarded URL.
          </p>
        </div>
      </div>
    </div>
    <div class="descriptions__table">
      <div class="descriptions__table-left">
        <div class="descriptions__table-header"><h4>Light API</h4></div>
        <ul>
          <li>
            <b>Light api flow with continue in partner system</b>
            <br/>
            after application is processed and decision has been made, callback will
            be sent to Partner to continuing application in Bigbank Partner Self-Service.
          </li>
          <li>
            <b>Light api flow with continue in Bigbank loan service</b>
            <br/>
            after application is processed and decision has been made, callback will be
            sent to Partner with continueLink to continue application in Bigbank public application.
          </li>
          <li>
            <b>Light api flow with bank account statement analysis from API</b>
            <br/>
            after application is processed and decision has been made, callback
            will be sent to Partner for continuing application in Bigbank Partner Self-Service.
            If bank account statement is needed callback is sent stating basNeeded,
            to upload bank account statement refer to
            <a href="#file-upload">upload files section</a>
          </li>
        </ul>
      </div>
      <div class="descriptions__table-right">
        <div class="descriptions__table-header"><h4>Full API</h4></div>
        <ul>
          <li>
            <b>Full api flow</b>
            <br/>
            after application is processed and decision has been made,
            callback will be sent to Partner for continuing application via Partner API.
          </li>
          <li>
            <b>Full api flow with bank account analysis in Bigbank loan service</b>
            <br/>
            after application is processed and decision has been made,
            callback will be sent to Partner for continuing application via Partner API.
            If bank account statement is needed callback is sent stating
            basNeeded and continueLink to Bigbank public application.
            When bank account statement is uploaded and processed regular
            full api flow continues
          </li>
          <li>
            <b>Multistep full api flow</b>
            <br/>
            after application is processed and decision has been made,
            callback will be sent to Partner for continuing application via Partner API.
          </li>
          <li>
            <b>
              After application submitting has been completed and final
              callback is sent, validate the correct status with
              <a href="#query-application">Query application</a> endpoint
            </b>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { BbTable } from '@bigbank/interface-components';

export default {
  name: 'descriptions-section',
  components: {
    BbTable,
  },
  props: {
    isExtended: {
      type: Boolean,
    },
  },
  data() {
    return {
      forwardRequestSample: 'https://web-loan-application-staging.bigbank.lv/?amount=1400&period=12&goods_name=Iphone11+Pro&reg_code=LV-1234&redirect_uri=https://my-web-shop.lv/order/123?status=success&reference_id=123&seller_business_name=BusinessName&seller_legal_name=LegalName&seller_reg_code=12345678&seller_phone_number=+3712222222&expiration_minutes_amount=10',
      hostedflowData: [
        {
          name: 'goods_name',
          type: 'String | Required',
          description: 'Comma separated itemised list of shopping cart, used for selecting product and campaign if applicable',
        },
        {
          name: 'reg_code',
          type: 'String | Required',
          description: 'Company registration code',
        },
        {
          name: 'amount',
          type: 'Number',
          description: 'Total shopping basket amount - down payment = amount',
        },
        {
          name: 'period',
          type: 'Number',
          description: 'Loan perios months',
        },
        {
          name: 'redirect_uri',
          type: 'String',
          description: 'URL where to redirect customer after application process has been finished in Bigbank',
        },
        {
          name: 'reference_id',
          type: 'String',
          description: 'Your unique Id for this transaction. Required to check the status of application (is signing completed successfully or is it rejected).',
        },
        {
          name: 'seller_business_name',
          type: 'String',
          description: 'Seller\'s business name.',
        },
        {
          name: 'seller_legal_name',
          type: 'String',
          description: 'Seller\'s business legal name.',
        },
        {
          name: 'seller_reg_code',
          type: 'String',
          description: 'Seller\'s business registration code.',
        },
        {
          name: 'seller_phone_number',
          type: 'String',
          description: 'Seller\'s phone number.',
        },
        {
          name: 'expiration_minutes_amount',
          type: 'String',
          description: 'Application expiration duration in minutes.',
        },
      ],
    };
  },
  computed: {
    getIntegrationUrl() {
      const domain = window.config.DOMAIN;
      return `https://web-loan-application-staging.bigbank.${domain.toLowerCase()}`;
    },
    getProductionUrl() {
      const domain = window.config.DOMAIN;
      return `https://loans.bigbank.${domain.toLowerCase()}`;
    },
  },
};
</script>

<style scoped lang="scss">
  a {
    text-decoration: underline;
  }
  .descriptions__table {
    display: flex;

    & + & {
      margin-top: 15px;
    }

    > div {
      width: 100%;
      padding: 10px;
      padding-bottom: 40px;
      border: 2px solid #ccc;
      border-radius: 5px;
    }

    &-right {
      margin-left: 15px;
    }

    &-inner {
      margin-top: 20px;
    }

    &-single {
      div + div {
        margin-top: 15px;
      }
    }
  }

  li {
    margin-left: 30px;
    & + & {
      margin-top: 10px;
    }
  }
</style>
