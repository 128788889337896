<template>
    <div class="nav__container">
      <bb-navbar
        sidebar
        flavor="landing"
        :links="getMenuItems"
      />
    </div>
</template>

<script>
import { BbNavbar } from '@bigbank/interface-components';

export default {
  components: {
    BbNavbar,
  },
  name: 'aside-nav',
  computed: {
    getMenuItems() {
      const domain = window.config.DOMAIN;
      if (domain === 'LV') {
        return [
          {
            label: 'Quickstart',
            to: '#quickstart',
          },
          {
            label: 'Authentication',
            to: '#authentication',
          },
          {
            label: 'Application',
            sections: [
              {
                label: 'New application',
                to: '#application',
              },
              {
                label: 'Query application',
                to: '#query-application',
              },
              {
                label: 'Annul application',
                to: '#annul-application',
              },
              {
                label: 'Get contract',
                to: '#contract',
              },
              {
                label: 'Mark contract signed',
                to: '#mark-signed',
              },
              {
                label: 'Accept offer',
                to: '#accept-offer',
              },
              {
                label: 'Update planned dates',
                to: '#update-dates',
              },
            ],
          },
          {
            label: 'File upload',
            to: '#file-upload',
          },
          {
            label: 'Product API',
            to: '#discounts',
          },
          {
            label: 'Countries',
            to: '#countries',
          },
        ];
      }
      if (domain === 'LT') {
        return [
          {
            label: 'Authentication',
            to: '#authentication',
          },
          {
            label: 'Application',
            sections: [
              {
                label: 'New application',
                to: '#application',
              },
              {
                label: 'Query application',
                to: '#query-application',
              },
            ],
          },
        ];
      }
      if (domain === 'EE') {
        return [
          {
            label: 'Authentication',
            to: '#authentication',
          },
          {
            label: 'Application',
            sections: [
              {
                label: 'New application',
                to: '#new-application',
              },
              {
                label: 'New light application',
                to: '#new-light-application',
              },
              {
                label: 'Query application',
                to: '#query-application',
              },
              {
                label: 'Annul application',
                to: '#annul-application',
              },
            ],
          },
        ];
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
  .nav__container {
    background-color: #ebebef;
    width: 250px;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .bb-drawer__body {
    display: block !important;
  }

  .bb-navbar {
    @media only screen and (max-width: 1200px) {
      width: 250px;
    }
  }
</style>
