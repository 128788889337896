export default function mainSchema() {
  return {
    LT: {
      description: 'API to send incoming customer applications',
      version: '2.1.2',
      title: 'Bigbank Partner API LT',
      contact: {
        name: 'API Support',
        url: 'https://www.bigbank.lt',
        email: 'info@bigbank.lt',
      },
      groups: [
        {
          name: 'Authentication',
          endpoints: [
            {
              method: 'POST',
              path: '/api/authorize',
              internalPath: 'authentication',
              name: 'Retrieve authentication token',
              description: 'Use this request to exchange apiKey and apiSecret for the authentication token. Authentication token is needed to make requests to all of the other endpoints. To get your apiKey and apiSecret, contact Bigbank.',
              parameters: [
                {
                  in: 'body',
                  name: 'apiKey',
                  description: 'API key',
                  example: 'api_key',
                  type: 'string',
                  required: true,
                },
                {
                  in: 'body',
                  name: 'apiSecret',
                  description: 'API secret',
                  example: 'api_secret',
                  type: 'string',
                  required: true,
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'Token successfully retrieved',
                  properties: [
                    {
                      name: 'expiresAt',
                      type: 'string',
                      format: 'date-time',
                    },
                    {
                      name: 'token',
                      type: 'string',
                      example: 'eyJhbGciOiJSUzUxMiJ9.eyJ1c....',
                    },
                  ],
                },
                {
                  status: '400',
                  description: 'Bad request',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '401',
                  description: 'Authorization invalid',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'New application',
          endpoints: [
            {
              method: 'POST',
              path: '/api/v2/application',
              internalPath: 'application',
              name: 'Application',
              description: 'Submit new loan application',
              exampleRequest: 'LtNewApplicationRequestTemplate',
              parameters: [
                {
                  in: 'body',
                  name: 'application',
                  description: 'application',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant',
                  type: 'object',
                  description: 'applicant',
                  required: true,
                },
                {
                  name: 'application.applicant.agreeToPersonalDataProcessing',
                  type: 'boolean',
                  description: 'Must be true',
                  required: true,
                },
                {
                  name: 'application.applicant.allowRegistryCheck',
                  type: 'boolean',
                  description: 'Must be true',
                  required: true,
                },
                {
                  name: 'application.applicant.customerIsPEP',
                  type: 'boolean',
                  description: 'If customer is politically exposed person',
                },
                {
                  name: 'application.applicant.email',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.firstName',
                  type: 'string',
                  required: true,
                  description: 'Must be allowed by the following regexp pattern[a-zA-ZąčęėįšųūžĄČĘĖĮŠŲŪŽ\\s-]*',
                },
                {
                  name: 'application.applicant.idDocumentType',
                  type: 'string[enum]',
                  required: true,
                  acceptedValues: '["PASSPORT","ID_CARD","FOREIGN_PASSPORT","FOREIGN_ID_CARD","TEMPORARY_RESIDENCE_PERMIT_CARD","PERMANENT_RESIDENCE_PERMIT_CARD","DIPLOMATIC_PASSPORT","DRIVERS_LICENSE","EU_RESIDENCE_PERMIT_CARD","ALIENS_PASSPORT","OTHER"]',
                },
                {
                  name: 'application.applicant.incomes',
                  type: 'array',
                  required: true,
                  description: '{"declaredNetMonthlyAmount": number,"typeInForm": string[enum]}',
                  acceptedValues: 'typeInForm: ["SALARY","PARENTAL_BENEFIT","CIVIL_SERVANT","BUSINESS","SELF_EMPLOYED","RENT","PENSION","FOREIGN_SALARY","DAILY_ALLOWANCES","OTHER","SPORT_OR_PERFORMANCE","AGRICULTURE"]',
                },
                {
                  name: 'application.applicant.liabilities',
                  type: 'array',
                  required: true,
                  description: '{ "declaredMonthlyPayment": number, "type": string[enum] }',
                  acceptedValues: '"type": ["MORTGAGE","CONSUMER_LOAN","CREDIT_CARD","HIRE_PURCHASE","OTHER"]',
                },
                {
                  name: 'application.applicant.mainMobileNo',
                  type: 'string',
                  required: true,
                  description: 'Must contain country code with + and without spaces. Allowed country codes "+353", "+358", \n"+370", "+371", "+372", "+31", "+33", "+44", "+45", "+46", "+47", "+49"',
                },
                {
                  name: 'application.applicant.maritalStatus',
                  type: 'string[enum]',
                  required: true,
                  acceptedValues: '["MARRIED","DIVORCED","SINGLE","WIDOW","COHABITING"]',
                },
                {
                  name: 'application.applicant.personalIdentityCode',
                  type: 'string',
                  required: true,
                  description: 'Must conform to Lithuanian identity code standards',
                },
                {
                  name: 'application.applicant.surname',
                  type: 'string',
                  required: true,
                  description: 'Must be allowed by the following regexp pattern[a-zA-ZąčęėįšųūžĄČĘĖĮŠŲŪŽ\\s-]*',
                },
                {
                  name: 'application.assetCondition',
                  type: 'string[enum]',
                  description: 'Required with car leasing product',
                  acceptedValues: '["NEW","USED"]',
                },
                {
                  name: 'application.campaignCode',
                  type: 'string',
                  description: 'Optional, needs to be registered in Bigbank',
                },
                {
                  name: 'application.goodsName',
                  type: 'string',
                  description: 'Required when using hire purchase product',
                },
                {
                  name: 'application.goodsPrice',
                  type: 'number',
                  description: 'Required when using hire purchase or car leasing product and must not be a negative value',
                },
                {
                  name: 'application.initialLoanAmount',
                  type: 'number',
                  description: 'Required when using consumer loan or car leasing product and must not be a negative value',
                },
                {
                  name: 'application.initialLoanPeriod',
                  required: true,
                  type: 'integer|int32',
                  description: 'In months. For hire purchase from 3 to 120 and 3 to 144 for car loan',
                },
                {
                  name: 'application.interestCalculationType',
                  type: 'string[enum]',
                  description: 'Required with car leasing product',
                  acceptedValues: '["FIX","6m"]',
                },
                {
                  name: 'application.marketingConsent',
                  type: 'boolean',
                  description: 'Marketing consent required when using full api application',
                },
                {
                  name: 'application.productName',
                  type: 'string[enum]',
                  description: 'Optional, needs to be registered in Bigbank',
                  acceptedValues: '["SMALL_LOAN","CAR_LOAN","RENOVATION_LOAN","REG_HIRE_PURCHASE","COMP_HIRE_PURCHASE","CAR_HIRE_PURCHASE","REFINANCING_LOAN","MEDICAL_LOAN","REAL_ESTATE_LOAN","HOUSING_LOAN","CAR_HIRE_PURCHASE_COLLATERAL","CAR_FINANCE_LEASE","UNMAPPED"]',
                },
                {
                  name: 'application.segmentName',
                  type: 'string',
                  description: 'If needed, use only agreed upon segmentNames',
                },
                {
                  name: 'application.selfFinancingAmount',
                  type: 'number',
                  description: 'required with car leasing product, optional with hire purchase product',
                },
                {
                  name: 'applicationReference',
                  type: 'string',
                  description: 'Unique id for imported application. If not given, API will generate unique ID.',
                },
                {
                  name: 'callbackApplicationAnnul',
                  type: 'string',
                  description: "URL, where to send 'annulApplication' events.",
                },
                {
                  name: 'callbackApplicationApproved',
                  type: 'string',
                  description: "URL, where to send 'approvedApplication' events.",
                },
                {
                  name: 'callbackApplicationCancel',
                  type: 'string',
                  description: "URL, where to send 'cancelApplication' events.",
                },
                {
                  name: 'callbackContractSigned',
                  type: 'string',
                  description: "URL, where to send 'contractSigned' events.",
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'OK',
                  properties: [
                    {
                      name: 'applicationReference',
                      type: 'string',
                      example: '3b3e3777-8888-4734-b216-26c59cb64214',
                    },
                    {
                      name: 'status',
                      type: 'enum',
                      example: '[PROCESSING]',
                    },
                  ],
                },
                {
                  status: '400',
                  description: 'Incoming application data is invalid',
                },
                {
                  status: '401',
                  description: 'Unauthorized call',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '409',
                  description: 'Reference number already exists',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Query application',
          endpoints: [
            {
              method: 'GET',
              path: '/api/v2/application/{applicationReferenceId}',
              internalPath: 'query-application',
              name: 'Query application',
              description: 'Query the status of given application',
              parameters: [
                {
                  name: 'applicationReferenceId',
                  in: 'path',
                  description: 'applicationReferenceId',
                  required: true,
                  type: 'string',
                },
                {
                  name: 'Token',
                  in: 'header',
                  description: 'Authentication token (retrieved via /api/authorize)',
                  required: true,
                  type: 'string',
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'OK',
                  properties: [
                    {
                      name: 'applicationReference',
                      type: 'string',
                      example: '3b3e3777-8888-4734-b216-26c59cb64214',
                    },
                    {
                      name: 'continueLink',
                      type: 'string',
                    },
                    {
                      name: 'status',
                      type: 'enum',
                      example: '[PROCESSING, APPROVED, REJECTED, ACTIVATED, ANNULLED, CONTRACT_SIGNED]',
                    },
                    {
                      name: 'offer',
                      type: 'object',
                    },
                    {
                      name: 'offer.administrationFee',
                      type: 'number',
                    },
                    {
                      name: 'offer.aprc',
                      type: 'number',
                    },
                    {
                      name: 'offer.conclusionFee',
                      type: 'number',
                    },
                    {
                      name: 'offer.currency',
                      type: 'string',
                      example: 'EUR',
                    },
                    {
                      name: 'offer.interestFromLoanSum',
                      type: 'number',
                    },
                    {
                      name: 'offer.interestRate',
                      type: 'number',
                    },
                    {
                      name: 'offer.offeredAmount',
                      type: 'number',
                    },
                    {
                      name: 'offer.offeredMaturity',
                      type: 'integer',
                      format: 'int32',
                    },
                    {
                      name: 'offer.offeredMonthlyPayment',
                      type: 'number',
                    },
                  ],
                },
                {
                  status: '400',
                  description: 'Incoming application data is invalid',
                },
                {
                  status: '401',
                  description: 'Unauthorized call',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '409',
                  description: 'Reference number already exists',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    LV: {
      version: '2.1.2',
      description: 'API to send incoming customer applications',
      title: 'Bigbank Partner API LV',
      contact: {
        name: 'API Support',
        url: 'https://www.bigbank.lv',
        email: 'info@bigbank.lv',
      },
      groups: [
        {
          name: 'Authentication',
          supportedCountries: [
            'LV',
          ],
          endpoints: [
            {
              method: 'POST',
              path: '/api/authorize',
              internalPath: 'authentication',
              name: 'Retrieve authentication token',
              description: 'Use this request to exchange apiKey and apiSecret for the authentication token. Authentication token is needed to make requests to all of the other endpoints. To get your apiKey and apiSecret, contact Bigbank.',
              supportedCountries: [
                'LV',
              ],
              parameters: [
                {
                  in: 'body',
                  name: 'apiKey',
                  description: 'API key',
                  example: 'api_key',
                  type: 'string',
                  required: true,
                },
                {
                  in: 'body',
                  name: 'apiSecret',
                  description: 'API secret',
                  example: 'api_secret',
                  type: 'string',
                  required: true,
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'Token successfully retrieved',
                  properties: [
                    {
                      name: 'expiresAt',
                      type: 'string',
                      format: 'date-time',
                    },
                    {
                      name: 'token',
                      type: 'string',
                      example: 'eyJhbGciOiJSUzUxMiJ9.eyJ1c....',
                    },
                  ],
                },
                {
                  status: '400',
                  description: 'Bad request',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '401',
                  description: 'Authorization invalid',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'New application',
          supportedCountries: [
            'LV',
          ],
          endpoints: [
            {
              method: 'POST',
              path: '/api/v2/application',
              internalPath: 'application',
              name: 'New application',
              hasDescriptions: true,
              supportedCountries: [
                'LV',
              ],
              exampleRequest: 'LvNewApplicationRequestTemplate',
              parameters: [
                {
                  name: 'application',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant.address',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant.address.address',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.address.city',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.address.country',
                  type: 'string',
                  required: true,
                  description: [
                    'LATVIA',
                  ],
                },
                {
                  name: 'application.applicant.address.county',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.address.postCode',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.agreeToPersonalDataProcessing',
                  type: 'boolean',
                  example: false,
                  required: true,
                },
                {
                  name: 'application.applicant.allowRegistryCheck',
                  type: 'boolean',
                  example: false,
                  required: true,
                },
                {
                  name: 'application.applicant.applicantIdentificationData',
                  description: 'Required only when specified in licence agreement',
                  type: 'object',
                },
                {
                  name: 'application.applicant.applicantIdentificationData.dateTime',
                  type: 'string',
                },
                {
                  name: 'application.applicant.applicantIdentificationData.documentNumber',
                  type: 'string',
                },
                {
                  name: 'application.applicant.applicantIdentificationData.method',
                  type: 'string',
                },
                {
                  name: 'application.applicant.bankAnnountNumber',
                  type: 'string',
                  description: 'Required for full API flow when using CAR_LOAN or SMALL_LOAN product',
                },
                {
                  name: 'application.applicant.citizenCountryCodes',
                  type: 'array',
                  description: 'Up to 2 unique country codes',
                  items: {
                    type: 'string',
                  },
                  required: true,
                },
                {
                  name: 'application.applicant.customerIsPEP',
                  type: 'boolean',
                  example: false,
                  required: true,
                },
                {
                  name: 'application.applicant.customerIsPEPComment',
                  type: 'string',
                  description: 'Comment is required when customerIsPEP is true, from 2 to 300 symbols',
                },
                {
                  name: 'application.applicant.dateOfBirth',
                  type: 'string',
                  format: 'date',
                  example: '1999-01-25',
                  description: 'Age must be in range of [19y - 73y11m] for hire purchase products or [20y - 73y11m] for consumer loan products',
                },
                {
                  name: 'application.applicant.declaredIncomeType',
                  type: 'string',
                  description: 'Income type is required when partner is not set to continue application in Bigbank web',
                  enum: [
                    'SALARY',
                    'PENSION',
                  ],
                },
                {
                  name: 'application.applicant.dependents',
                  type: 'string',
                  description: [
                    '0',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10_OR_MORE',
                  ],
                  required: true,
                },
                {
                  name: 'application.applicant.education',
                  type: 'string',
                  description: 'Required for CAR_LOAN and SMALL_LOAN products',
                  enum: [
                    'BASIC',
                    'SECONDARY',
                    'SECONDARY_SPECIALISED',
                    'HIGHER',
                  ],
                },
                {
                  name: 'application.applicant.email',
                  type: 'string',
                  description: 'Up to 80 symbols',
                  required: true,
                },
                {
                  name: 'application.applicant.employment',
                  description: 'Required for full API flow',
                  type: 'object',
                },
                {
                  name: 'application.applicant.employment.beginningOfEmployment',
                  type: 'string',
                  format: 'date',
                },
                {
                  name: 'application.applicant.employment.declaredCompanyName',
                  type: 'string',
                  description: 'Required when consumer loan product and income type salary',
                },
                {
                  name: 'application.applicant.firstName',
                  type: 'string',
                  description: 'From 2 to 35 symbols',
                  required: true,
                },
                {
                  name: 'application.applicant.idDocument',
                  description: 'Required for full API flow',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant.idDocument.dateOfExpiry',
                  type: 'string',
                  format: 'date',
                  required: true,
                },
                {
                  name: 'application.applicant.idDocument.number',
                  type: 'string',
                  description: 'From 2 to 35 symbols',
                  required: true,
                },
                {
                  name: 'application.applicant.idDocument.type',
                  type: 'string',
                  required: true,
                  enum: [
                    'ID_CARD',
                    'PASSPORT',
                  ],
                },
                {
                  name: 'application.applicant.mainMobileNo',
                  type: 'number',
                  example: '25556729',
                  required: true,
                },
                {
                  name: 'application.applicant.mainMobileNoCountryCode',
                  type: 'string',
                  example: '+371',
                  required: true,
                },
                {
                  name: 'application.applicant.maritalStatus',
                  type: 'string',
                  enum: [
                    'MARRIED',
                    'DIVORCED',
                    'SINGLE',
                    'WIDOW',
                    'COHABITING',
                  ],
                  required: true,
                },
                {
                  name: 'application.applicant.ownedAddress',
                  description: 'Required when using RENOVATION_LOAN product',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant.ownedAddress.address',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.ownedAddress.city',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.ownedAddress.country',
                  type: 'string',
                  required: true,
                  enum: [
                    'LATVIA',
                  ],
                },
                {
                  name: 'application.applicant.ownedAddress.county',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.personalIdentityCode',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.phonePassword',
                  type: 'string',
                  description: 'Required for full API flow, from 3 to 10 symbols',
                },
                {
                  name: 'application.applicant.residenceType',
                  type: 'string',
                  description: 'Required for CAR_LOAN and SMALL_LOAN products',
                  enum: [
                    'PRIVATE_APARTMENT',
                    'PRIVATE_HOUSE',
                    'RENTAL_PREMISES',
                    'LIVING_WITH_PARENTS',
                    'OTHER',
                  ],
                },
                {
                  name: 'application.applicant.surname',
                  type: 'string',
                  description: 'From 2 to 35 symbols',
                  required: true,
                },
                {
                  name: 'application.applicant.ownedAddress.postCode',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.campaignCode',
                  type: 'string',
                },
                {
                  name: 'application.expectedContractSigningDate',
                  type: 'string',
                  format: 'date',
                  description: 'Can not be in past or more than 7 days in the future',
                },
                {
                  name: 'application.goodsName',
                  type: 'string',
                  description: 'Required when using Hire Purchase product',
                },
                {
                  name: 'application.goodsPrice',
                  type: 'number',
                  description: 'Required when using Hire Purchase product',
                },
                {
                  name: 'application.initialLoanAmount',
                  required: true,
                  type: 'number',
                  description: 'From 50 to 100000',
                },
                {
                  name: 'application.initialLoanPeriod',
                  required: true,
                  type: 'integer|int32',
                  example: 12,
                  description: 'In month, from 1 to 180',
                },
                {
                  name: 'application.marketingConsent',
                  type: 'boolean',
                },
                {
                  name: 'application.monthlyPaymentDay',
                  type: 'integer',
                  format: 'int32',
                  description: 'Required for full API flow',
                  enum: [
                    1,
                    5,
                    10,
                    15,
                    20,
                  ],
                },
                {
                  name: 'application.plannedActivationDate',
                  type: 'string',
                  format: 'date',
                },
                {
                  name: 'application.productName',
                  type: 'string',
                  enum: [
                    'SMALL_LOAN',
                    'CAR_LOAN',
                    'RENOVATION_LOAN',
                    'REG_HIRE_PURCHASE',
                    'COMP_HIRE_PURCHASE',
                    'CAR_HIRE_PURCHASE',
                    'REFINANCING_LOAN',
                    'MEDICAL_LOAN',
                    'REAL_ESTATE_LOAN',
                    'HOUSING_LOAN',
                    'CAR_HIRE_PURCHASE_COLLATERAL',
                    'CAR_FINANCE_LEASE',
                    'UNMAPPED',
                  ],
                  required: true,
                },
                {
                  name: 'application.regCode',
                  type: 'string',
                  description: 'Enter registration code for partner to whom application is for',
                },
                {
                  name: 'application.segmentName',
                  type: 'string',
                  description: 'Applicable segment names can be retrieved via Product API',
                },
                {
                  name: 'application.selfFinancingAmount',
                  type: 'number',
                },
                {
                  name: 'application.applicationReference',
                  type: 'string',
                  description: 'Unique id for imported application. If not given, API will generate unique ID.',
                },
                {
                  name: 'application.callbackApplicationAnnul',
                  type: 'string',
                  description: "URL, where to send 'annulApplication' events.",
                },
                {
                  name: 'application.callbackApplicationApproved',
                  type: 'string',
                  description: "URL, where to send 'approvedApplication' events.",
                },
                {
                  name: 'application.callbackApplicationApprovedAdditionalCheck',
                  type: 'string',
                  description: "URL, where to send 'approvedAdditionalCheckApplication' events.",
                },
                {
                  name: 'application.callbackApplicationCancel',
                  type: 'string',
                  description: "URL, where to send 'cancelApplication' events.",
                },
                {
                  name: 'application.callbackContractPayout',
                  type: 'string',
                  description: "URL, where to send 'payoutContract' events.",
                },
                {
                  name: 'application.callbackContractSigned',
                  type: 'string',
                  description: "URL, where to send 'contractSigned' events.",
                },
                {
                  name: 'application.callbackOfferChanged',
                  type: 'string',
                  description: "URL, where to send 'offerChanged' events.",
                },
                {
                  name: 'Token',
                  in: 'header',
                  description: 'Authentication token (retrieved via /api/authorize)',
                  required: true,
                  type: 'string',
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'OK',
                  type: 'object',
                  properties: [
                    {
                      name: 'applicationReference',
                      type: 'string',
                      example: '3b3e3777-8888-4734-b216-26c59cb64214',
                    },
                    {
                      name: 'status',
                      type: 'string',
                      enum: [
                        'PROCESSING',
                      ],
                    },
                  ],
                },
                {
                  status: '400',
                  description: 'Incoming application data is invalid',
                },
                {
                  status: '401',
                  description: 'Unauthorized call',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '409',
                  description: 'Reference number already exists',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Query application',
          supportedCountries: [
            'LV',
          ],
          endpoints: [
            {
              method: 'GET',
              path: '/api/v2/application/{applicationReferenceId}',
              internalPath: 'query-application',
              name: 'Query application',
              description: 'Query existing application',
              supportedCountries: [
                'LV',
              ],
              parameters: [
                {
                  name: 'applicationReferenceId',
                  in: 'path',
                  description: 'applicationReferenceId',
                  required: true,
                  type: 'string',
                },
                {
                  name: 'Token',
                  in: 'header',
                  description: 'Authentication token (retrieved via /api/authorize)',
                  required: true,
                  type: 'string',
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'OK',
                  type: 'object',
                  properties: [
                    {
                      name: 'applicationReference',
                      type: 'string',
                      example: '3b3e3777-8888-4734-b216-26c59cb64214',
                    },
                    {
                      name: 'basErrors',
                      type: 'array',
                      description: 'This is deprecated. Please use invalidBas property instead',
                      items: {
                        type: 'string',
                      },
                    },
                    {
                      name: 'basUploadNeeded',
                      type: 'boolean',
                    },
                    {
                      name: 'invalidBas',
                      type: 'array',
                      properties: [
                        {
                          name: 'errors',
                          contentType: 'string',
                        },
                        {
                          name: 'fileType',
                          type: 'string',
                        },
                      ],
                    },
                    {
                      name: 'offer',
                      type: 'object',
                      properties: [
                        {
                          name: 'administrationFee',
                          type: 'number',
                        },
                        {
                          name: 'aprc',
                          type: 'number',
                        },
                        {
                          name: 'conclusionFee',
                          type: 'number',
                        },
                        {
                          name: 'currency',
                          type: 'string',
                          example: 'EUR',
                        },
                        {
                          name: 'customerMaxAmount',
                          type: 'number',
                        },
                        {
                          name: 'customerMaxMonthlyPaymentFull',
                          type: 'number',
                        },
                        {
                          name: 'customerMaxPeriod',
                          type: 'integer',
                          format: 'int32',
                        },
                        {
                          name: 'interestFromLoanSum',
                          type: 'number',
                        },
                        {
                          name: 'interestRate',
                          type: 'number',
                        },
                        {
                          name: 'offeredAmount',
                          type: 'number',
                        },
                        {
                          name: 'offeredMaturity',
                          type: 'integer',
                          format: 'int32',
                        },
                        {
                          name: 'offeredMonthlyPayment',
                          type: 'number',
                        },
                        {
                          name: 'payoutSum',
                          type: 'number',
                        },
                        {
                          name: 'totalPayableSum',
                          type: 'number',
                        },
                      ],
                    },
                    {
                      name: 'status',
                      type: 'string',
                      enum: [
                        'PROCESSING',
                        'APPROVED',
                        'REJECTED',
                        'ACTIVATED',
                        'ANNULLED',
                        'CONTRACT_SIGNED',
                      ],
                    },
                  ],
                },
                {
                  status: '400',
                  description: 'Incoming data is invalid',
                },
                {
                  status: '401',
                  description: 'Unauthorized call',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '404',
                  description: 'Application with given reference not found',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Annul application',
          supportedCountries: [
            'LV',
          ],
          endpoints: [
            {
              method: 'POST',
              path: '/api/v2/application/{applicationReferenceId}/annulment',
              internalPath: 'annul-application',
              name: 'Annul application',
              description: 'Annul application',
              supportedCountries: [
                'LV',
              ],
              parameters: [
                {
                  name: 'applicationReferenceId',
                  in: 'path',
                  description: 'applicationReferenceId',
                  required: true,
                  type: 'string',
                },
                {
                  name: 'Token',
                  in: 'header',
                  description: 'Authentication token (retrieved via /api/authorize)',
                  required: true,
                  type: 'string',
                },
              ],
              responses: [
                {
                  status: '204',
                  description: 'OK',
                },
                {
                  status: '401',
                  description: 'Unauthorized call',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '404',
                  description: 'Application with given reference not found',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Get contract',
          supportedCountries: [
            'LV',
          ],
          endpoints: [
            {
              method: 'GET',
              path: '/api/v2/application/{applicationReferenceId}/contract',
              internalPath: 'contract',
              name: 'Get contract',
              description: 'When requesting for new loan amount and/or period, then bear in mind that client monthly payment, should not exceed customerMaxMonthlyPaymentFull stated in the previous offer',
              supportedCountries: [
                'LV',
              ],
              parameters: [
                {
                  name: 'applicationReferenceId',
                  in: 'path',
                  description: 'applicationReferenceId',
                  required: true,
                  type: 'string',
                },
                {
                  name: 'Token',
                  in: 'header',
                  description: 'Authentication token (retrieved via /api/authorize)',
                  required: true,
                  type: 'string',
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'OK',
                  type: 'object',
                  properties: [
                    {
                      name: 'result',
                      type: 'object',
                    },
                    {
                      name: 'setOrExpired',
                      type: 'boolean',
                    },
                  ],
                },
                {
                  status: '401',
                  description: 'Unauthorized call',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '404',
                  description: 'Application with given reference not found',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Mark contract signed',
          supportedCountries: [
            'LV',
          ],
          endpoints: [
            {
              method: 'POST',
              path: '/api/v2/application/{applicationReferenceId}/contract/mark-signed',
              internalPath: 'mark-signed',
              name: 'Mark contract signed',
              description: 'Mark contract signed',
              supportedCountries: [
                'LV',
              ],
              parameters: [
                {
                  name: 'applicationReferenceId',
                  in: 'path',
                  description: 'applicationReferenceId',
                  required: true,
                  type: 'string',
                },
                {
                  name: 'Token',
                  in: 'header',
                  description: 'Authentication token (retrieved via /api/authorize)',
                  required: true,
                  type: 'string',
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'OK',
                },
                {
                  status: '401',
                  description: 'Unauthorized call',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '404',
                  description: 'Application with given reference not found',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Accept offer',
          supportedCountries: [
            'LV',
          ],
          endpoints: [
            {
              method: 'POST',
              path: '/api/v2/application/{applicationReferenceId}/offer-accept',
              internalPath: 'accept-offer',
              name: 'Accept offer',
              description: 'Accept offer',
              supportedCountries: [
                'LV',
              ],
              parameters: [
                {
                  name: 'applicationReferenceId',
                  in: 'path',
                  description: 'applicationReferenceId',
                  required: true,
                  type: 'string',
                },
                {
                  name: 'Token',
                  in: 'header',
                  description: 'Authentication token (retrieved via /api/authorize)',
                  required: true,
                  type: 'string',
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'Successfully accepted offer and completed task',
                  type: 'string',
                },
                {
                  status: '400',
                  description: 'Cannot update rejected application',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '401',
                  description: 'Unauthorized call',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '404',
                  description: 'Application with provided reference was not found',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '409',
                  description: 'Application is not in waiting for partner confirmation status',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  type: 'object',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Update planned dates',
          supportedCountries: [
            'LV',
          ],
          endpoints: [
            {
              method: 'PUT',
              path: '/api/v2/application/{applicationReferenceId}/planned-dates',
              internalPath: 'update-dates',
              name: 'Update planned activation/signing dates',
              description: 'Update planned activation/signing dates',
              supportedCountries: [
                'LV',
              ],
              parameters: [
                {
                  name: 'applicationReferenceId',
                  in: 'path',
                  description: 'applicationReferenceId',
                  required: true,
                  type: 'string',
                },
                {
                  name: 'plannedActivationDate',
                  type: 'string',
                  format: 'date',
                  required: true,
                  in: 'body',
                },
                {
                  name: 'plannedSigningDate',
                  type: 'string',
                  format: 'date',
                  required: true,
                  description: 'Can not be in past or more than 7 days after application creation',
                  in: 'body',
                },
                {
                  name: 'Token',
                  in: 'header',
                  description: 'Authentication token (retrieved via /api/authorize)',
                  required: true,
                  type: 'string',
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'OK',
                  type: 'string',
                },
              ],
            },
          ],
        },
        {
          name: 'File upload API',
          supportedCountries: [
            'LV',
          ],
          endpoints: [
            {
              method: 'POST',
              path: '/api/v2/file/upload',
              internalPath: 'file-upload',
              name: 'Upload files',
              description: 'Important notes: <br>* max 5 files up to 10MB each<br>* .pdf and .asice are allowed file extension.<br>* Currently supported fileType are: BANK_STATEMENT, SIGNED_CONTRACT<br>* Upload BANK_STATEMENT only when respective callback has been received. ',
              supportedCountries: [
                'LV',
              ],
              parameters: [
                {
                  name: 'file',
                  in: 'formData',
                  description: 'file',
                  type: 'array',
                  required: true,
                  items: {
                    type: 'file',
                  },
                  collectionFormat: 'multi',
                },
                {
                  name: 'applicationReferenceId',
                  in: 'query',
                  description: 'applicationReferenceId',
                  required: true,
                  type: 'string',
                },
                {
                  name: 'fileType',
                  in: 'query',
                  description: [
                    'BANK_STATEMENT',
                    'SIGNED_CONTRACT',
                    'UNSIGNED_CONTRACT',
                    'PASSPORT',
                    'ID_CARD',
                    'PERMANENT_RESIDENCE_PERMIT_CARD',
                    'TEMPORARY_RESIDENCE_PERMIT_CARD',
                    'FOREIGN_PASSPORT',
                    'LIABILITIES_PROOF',
                    'OFFER_FROM_OTHER_CREDIT_PROVIDER',
                    'REGISTRY_RESPONSE_NDR',
                    'REGISTRY_RESPONSE',
                    'LOAN_APPLICATION',
                  ],
                  required: true,
                  type: 'string',
                },
                {
                  name: 'Token',
                  in: 'header',
                  description: 'Authentication token (retrieved via /api/authorize)',
                  required: true,
                  type: 'string',
                },
              ],
              responses: [
                {
                  status: '204',
                  description: 'No content',
                },
              ],
            },
          ],
        },
        {
          name: 'Product API',
          supportedCountries: [
            'LV',
          ],
          endpoints: [
            {
              method: 'GET',
              path: '/api/v2/discounts',
              internalPath: 'discounts',
              name: 'Product segments and campaigns',
              description: 'Get product segments and campaigns',
              supportedCountries: [
                'LV',
              ],
              parameters: [
                {
                  name: 'Token',
                  in: 'header',
                  description: 'Authentication token (retrieved via /api/authorize)',
                  type: 'string',
                  required: true,
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'OK',
                  properties: [
                    {
                      name: 'products',
                      type: 'object',
                    },
                    {
                      name: 'products.campaigns',
                      type: 'array of strings',
                      content: 'items',
                    },
                    {
                      name: 'products.segments',
                      type: 'array of strings',
                      content: 'items',
                    },
                  ],
                },
                {
                  status: '401',
                  description: 'Unauthorized call',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Countries',
          supportedCountries: [
            'LV',
          ],
          endpoints: [
            {
              method: 'GET',
              path: '/api/v2/countries',
              internalPath: 'countries',
              name: 'Countries options',
              description: 'Countries options',
              supportedCountries: [
                'LV',
              ],
              parameters: [
                {
                  in: 'header',
                  name: 'Token',
                  description: 'Authentication token (retrieved via /api/authorize)',
                  type: 'string',
                  required: true,
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'OK',
                  properties: [
                    {
                      name: 'items',
                      type: 'object',
                    },
                  ],
                },
                {
                  status: '401',
                  description: 'Unauthorized request',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    EE: {
      description: 'API to send incoming customer applications',
      version: '2.1.2',
      title: 'Bigbank Broker API EE',
      contact: { name: 'API Support', url: 'http://www.bigbank.ee', email: 'info@bigbank.ee' },
      groups: [
        {
          name: 'Authentication',
          endpoints: [
            {
              method: 'POST',
              path: '/api/authorize',
              internalPath: 'authentication',
              name: 'Retrieve authentication token',
              description: 'Use this request to exchange apiKey and apiSecret for the authentication token. Authentication token is needed to make requests to all of the other endpoints. To get your apiKey and apiSecret, contact Bigbank.',
              parameters: [
                {
                  in: 'body',
                  name: 'apiKey',
                  description: 'API key',
                  example: 'api_key',
                  type: 'string',
                  required: true,
                },
                {
                  in: 'body',
                  name: 'apiSecret',
                  description: 'API secret',
                  example: 'api_secret',
                  type: 'string',
                  required: true,
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'Token successfully retrieved',
                  properties: [
                    {
                      name: 'expiresAt',
                      type: 'string',
                      format: 'date-time',
                    },
                    {
                      name: 'token',
                      type: 'string',
                      example: 'eyJhbGciOiJSUzUxMiJ9.eyJ1c....',
                    },
                  ],
                },
                {
                  status: '400',
                  description: 'Bad request',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '401',
                  description: 'Authorization invalid',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'New application',
          endpoints: [
            {
              method: 'POST',
              path: '/api/v2/application',
              internalPath: 'new-application',
              name: 'New application',
              description: 'This endpoint can also be used for light application (for that follow examples under light application endpoint)',
              exampleRequest: 'EeNewApplicationRequestTemplate',
              parameters: [
                {
                  name: 'application',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant.addresses',
                  type: 'array',
                  required: true,
                },
                {
                  name: 'application.applicant.addresses[n]',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant.addresses[n].address',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.addresses[n].country',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.addresses[n].county',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.addresses[n].flatNumber',
                  type: 'string',
                },
                {
                  name: 'application.applicant.addresses[n].houseNumber',
                  type: 'string',
                },
                {
                  name: 'application.applicant.addresses[n].municipality',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.addresses[n].postCode',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.addresses[n].settlementUnit',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.addresses[n].street',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.addresses[n].type',
                  type: 'string|enum',
                  example: 'CONTACT',
                  acceptedValues: '"CONTACT"',
                  required: true,
                },
                {
                  name: 'application.applicant.agreeToPersonalDataProcessing',
                  type: 'boolean',
                  required: true,
                },
                {
                  name: 'application.applicant.bankAccountNumber',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.customerIsPEP',
                  type: 'boolean',
                  required: true,
                },
                {
                  name: 'application.applicant.declaredMonthlyPayment',
                  type: 'number',
                  required: true,
                },
                {
                  name: 'application.applicant.declaredNetMonthlyAmount',
                  type: 'number',
                  required: true,
                },
                {
                  name: 'application.applicant.dependents',
                  type: 'string|enum',
                  example: '3',
                  acceptedValues: '"0", "1", "2", "3", "4", "5_OR_MORE"',
                  required: true,
                },
                {
                  name: 'application.applicant.education',
                  type: 'string|enum',
                  example: 'BASIC',
                  acceptedValues: '"BASIC", "SECONDARY", "SECONDARY_SPECIALISED", "HIGHER_BA", "HIGHER_MA", "HIGHER_PHD", "UNMAPPED"',
                  required: true,
                },
                {
                  name: 'application.applicant.email',
                  type: 'string',
                  example: '"example@mail.com"',
                  required: true,
                },
                {
                  name: 'application.applicant.employment',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant.employment.companyName',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.employment.fieldOfActivity',
                  type: 'string',
                  example: 'PRIVATE_SECTOR',
                  required: true,
                },
                {
                  name: 'application.applicant.firstName',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.idDocumentNumber',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.mainMobileNo',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.maritalStatus',
                  type: 'string|enum',
                  example: 'MARRIED',
                  acceptedValues: '"MARRIED", "DIVORCED", "SINGLE", "WIDOW", "COHABITING"',
                  required: true,
                },
                {
                  name: 'application.applicant.personalIdentityCode',
                  type: 'string',
                  description: 'Must conform to Estonian identity code standards',
                  required: true,
                },
                {
                  name: 'application.applicant.residenceType',
                  type: 'string|enum',
                  acceptedValues: '"PRIVATE_APARTMENT", "PRIVATE_HOUSE", "RENTAL_PREMISES", "LIVING_WITH_PARENTS", "OTHER", "UNMAPPED"',
                  required: true,
                },
                {
                  name: 'application.applicant.surname',
                  type: 'string',
                  description: 'Must be allowed by the following regexp pattern^[^0123456789/\\\\*@£$%&{}\\[\\]^;:.,´_+"!?~\u003C\u003E|§#¤=«»¶©№„“”“’¨™]*$',
                  required: true,
                },
                {
                  name: 'application.campaignCode',
                  type: 'string',
                  description: 'Product\'s discount code. Optional, needs to be registered in Bigbank',
                },
                {
                  name: 'application.goodsName',
                  type: 'string',
                  description: 'Required when using hire purchase product',
                },
                {
                  name: 'application.goodsPrice',
                  type: 'number',
                  description: 'Required when using hire purchase product and must not be a negative value',
                },
                {
                  name: 'application.initialLoanAmount',
                  type: 'number',
                  description: 'Required when using consumer loan products',
                },
                {
                  name: 'application.initialLoanPeriod',
                  type: 'integer|int32',
                  description: 'In month. For hire purchase from 3 to 72, for consumer loan 3 to 120',
                  required: true,
                },
                {
                  name: 'application.marketingConsent',
                  type: 'boolean',
                  required: true,
                },
                {
                  name: 'application.monthlyPaymentDay',
                  type: 'enum|integer|int32',
                  required: true,
                  acceptedValues: '5, 10, 15, 25',
                },
                {
                  name: 'application.productName',
                  type: 'string',
                  acceptedValues: '"SMALL_LOAN", "CAR_LOAN", "REG_HIRE_PURCHASE", "COMP_HIRE_PURCHASE", "CAR_HIRE_PURCHASE"',
                  required: true,
                },
                {
                  name: 'application.segmentName',
                  type: 'string',
                  description: 'Product\'s discount code. Optional, needs to be registered in Bigbank',
                  required: true,
                },
                {
                  name: 'application.selfFinancingAmount',
                  type: 'number',
                  description: 'Optional, usable with HP products',
                },
                {
                  name: 'application.seller',
                  type: 'object',
                  description: 'Seller required when integrating partner is brokering for other partners',
                  required: true,
                },
                {
                  name: 'application.seller.name',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.seller.registrationCode',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'applicationReference',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'callbackApplicationAdditionalDataNeeded',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'callbackApplicationAnnul',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'callbackApplicationApproved',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'callbackApplicationCancel',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'callbackContractSigned',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'callbackSignContract',
                  type: 'string',
                  required: true,
                },
              ],
              responses: [
                {
                  status: '200',
                  description: 'OK',
                  properties: [
                    {
                      name: 'applicationReference',
                      type: 'string',
                      example: '3b3e3777-8888-4734-b216-26c59cb64214',
                    },
                    {
                      name: 'status',
                      type: 'enum|string',
                      example: 'PROCESSING',
                    },
                  ],
                },
                {
                  status: '400',
                  description: 'Incoming application data is invalid',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '401',
                  description: 'Unauthorized call',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '409',
                  description: 'Reference number already exists',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
                {
                  status: '500',
                  description: 'Unexpected server error',
                  properties: [
                    {
                      name: 'error',
                      type: 'string',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'New light application',
          endpoints: [
            {
              method: 'POST',
              name: 'New light application',
              exampleRequest: 'EeNewLightApplicationRequestTemplate',
              internalPath: 'new-light-application',
              description: 'This is an asynchronous endpoint. Instead of having to make a full API integration between your service and Bigbank, you can create a new application with minimal mandatory input data and receive a synchronous response with a decision and if approved, a link to Bigbank\'s web flow for the customer to complete the application.',
              parameters: [
                {
                  name: 'application',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant.agreeToPersonalDataProcessing',
                  type: 'boolean',
                  required: true,
                },
                {
                  name: 'application.applicant.bankAccountNumber',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.customerIsPEP',
                  type: 'boolean',
                  required: true,
                },
                {
                  name: 'application.applicant.declaredMonthlyPayment',
                  type: 'number',
                  required: true,
                },
                {
                  name: 'application.applicant.declaredNetMonthlyAmount',
                  type: 'number',
                  required: true,
                },
                {
                  name: 'application.applicant.dependents',
                  type: 'string|enum',
                  example: '3',
                  acceptedValues: '"0", "1", "2", "3", "4", "5_OR_MORE"',
                  required: true,
                },
                {
                  name: 'application.applicant.education',
                  type: 'string|enum',
                  example: 'BASIC',
                  acceptedValues: '"BASIC", "SECONDARY", "SECONDARY_SPECIALISED", "HIGHER_BA", "HIGHER_MA", "HIGHER_PHD", "UNMAPPED"',
                  required: true,
                },
                {
                  name: 'application.applicant.email',
                  type: 'string',
                  example: '"example@mail.com"',
                  required: true,
                },
                {
                  name: 'application.applicant.employment',
                  type: 'object',
                  required: true,
                },
                {
                  name: 'application.applicant.employment.companyName',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.employment.fieldOfActivity',
                  type: 'string',
                  example: 'PRIVATE_SECTOR',
                  required: true,
                },
                {
                  name: 'application.applicant.firstName',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.mainMobileNo',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.applicant.maritalStatus',
                  type: 'string|enum',
                  example: 'MARRIED',
                  acceptedValues: '"MARRIED", "DIVORCED", "SINGLE", "WIDOW", "COHABITING"',
                  required: true,
                },
                {
                  name: 'application.applicant.personalIdentityCode',
                  type: 'string',
                  description: 'Must conform to Estonian identity code standards',
                  required: true,
                },
                {
                  name: 'application.applicant.residenceType',
                  type: 'string|enum',
                  acceptedValues: '"PRIVATE_APARTMENT", "PRIVATE_HOUSE", "RENTAL_PREMISES", "LIVING_WITH_PARENTS", "OTHER", "UNMAPPED"',
                  required: true,
                },
                {
                  name: 'application.applicant.surname',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'application.campaignCode',
                  type: 'string',
                  description: 'Product\'s discount code. Optional, needs to be registered in Bigbank',
                },
                {
                  name: 'application.goodsName',
                  type: 'string',
                  description: 'Required when using hire purchase product',
                },
                {
                  name: 'application.goodsPrice',
                  type: 'number',
                  description: 'Required when using hire purchase product and must not be a negative value',
                },
                {
                  name: 'application.initialLoanAmount',
                  type: 'number',
                  description: 'Required when using consumer loan products',
                },
                {
                  name: 'application.initialLoanPeriod',
                  type: 'integer|int32',
                  description: 'In month. For hire purchase from 3 to 72, for consumer loan 3 to 120',
                  required: true,
                },
                {
                  name: 'application.productName',
                  type: 'string',
                  acceptedValues: '"SMALL_LOAN", "CAR_LOAN", "REG_HIRE_PURCHASE", "COMP_HIRE_PURCHASE", "CAR_HIRE_PURCHASE"',
                  required: true,
                },
                {
                  name: 'application.segmentName',
                  type: 'string',
                  description: 'Product\'s discount code. Optional, needs to be registered in Bigbank',
                  required: true,
                },
                {
                  name: 'application.selfFinancingAmount',
                  type: 'number',
                  description: 'Optional, usable with HP products',
                },
                {
                  name: 'applicationReference',
                  type: 'string',
                },
                {
                  name: 'callbackApplicationAnnul',
                  type: 'string',
                  description: 'Callback URL for annulment',
                },
                {
                  name: 'callbackApplicationCancel',
                  type: 'string',
                  description: 'Callback URL for annulment cancellation',
                },
                {
                  name: 'callbackContractSigned',
                  type: 'string',
                  description: 'Callback URL for application "SIGNED" status',
                },
              ],
            },
          ],
          responses: [
            {
              status: '200',
              description: 'OK',
              properties: [
                {
                  name: 'applicationReference',
                  type: 'string',
                  example: '3b3e3777-8888-4734-b216-26c59cb64214',
                },
                {
                  name: 'status',
                  type: 'enum|string',
                  example: 'PROCESSING',
                },
              ],
            },
            {
              status: '400',
              description: 'Incoming application data is invalid',
              properties: [
                {
                  name: 'error',
                  type: 'string',
                },
              ],
            },
            {
              status: '401',
              description: 'Unauthorized call',
              properties: [
                {
                  name: 'error',
                  type: 'string',
                },
              ],
            },
            {
              status: '409',
              description: 'Reference number already exists',
              properties: [
                {
                  name: 'error',
                  type: 'string',
                },
              ],
            },
            {
              status: '500',
              description: 'Unexpected server error',
              properties: [
                {
                  name: 'error',
                  type: 'string',
                },
              ],
            },
          ],
        },
        {
          name: 'Query application',
          endpoints: [
            {
              method: 'GET',
              name: 'Get application by reference ID',
              path: '/api/v2/application/{applicationReferenceId}',
              description: 'Query the status of given application',
              internalPath: 'query-application',
              parameters: [
                {
                  name: 'applicationReferenceId',
                  in: 'path',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'token',
                  in: 'header',
                  type: 'string',
                  description: 'auth token (retrieved via /api/authorize)',
                  required: true,
                },
              ],
            },
          ],
          responses: [
            {
              status: '200',
              description: 'OK',
              properties: [
                {
                  name: 'applicationReference',
                  type: 'string',
                  example: '3b3e3777-8888-4734-b216-26c59cb64214',
                },
                {
                  name: 'status',
                  type: 'enum|string',
                  example: 'PROCESSING',
                },
              ],
            },
            {
              status: '400',
              description: 'Incoming application data is invalid',
              properties: [
                {
                  name: 'error',
                  type: 'string',
                },
              ],
            },
            {
              status: '401',
              description: 'Unauthorized call',
              properties: [
                {
                  name: 'error',
                  type: 'string',
                },
              ],
            },
            {
              status: '404',
              description: 'Application with given reference not found',
              properties: [
                {
                  name: 'error',
                  type: 'string',
                },
              ],
            },
            {
              status: '500',
              description: 'Unexpected server error',
              properties: [
                {
                  name: 'error',
                  type: 'string',
                },
              ],
            },
          ],
        },
        {
          name: 'Annul application',
          endpoints: [
            {
              method: 'POST',
              name: 'Annul application by reference ID',
              path: '/api/v2/application/{applicationReferenceId}/annulment',
              description: 'Annul application',
              internalPath: 'annul-application',
              parameters: [
                {
                  name: 'applicationReferenceId',
                  in: 'path',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'token',
                  in: 'header',
                  type: 'string',
                  description: 'auth token (retrieved via /api/authorize)',
                  required: true,
                },
              ],
            },
          ],
          responses: [
            {
              status: '204',
              description: 'No Content',
            },
            {
              status: '404',
              description: 'Application with given reference not found',
              properties: [
                {
                  name: 'error',
                  type: 'string',
                },
              ],
            },
            {
              status: '500',
              description: 'Unexpected server error',
              properties: [
                {
                  name: 'error',
                  type: 'string',
                },
              ],
            },
          ],
        },
      ],
    },

  };
}
