<template>
  <div id="app">
    <aside-nav/>
    <router-view/>
  </div>
</template>

<script>
import AsideNav from '@/components/AsideNav.vue';
import Vue from 'vue';
import axios from 'axios';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'App',
  components: {
    AsideNav,
  },
};
</script>

<style lang="scss">
*, html, body {
  box-sixing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
  background-color: #f7f7f8;
  display: flex;
  flex-direction: row;
}
a {
  text-decoration: none;
  color: inherit;
}
.text--boxed {
  padding: 2px 5px;
  border: 2px solid #dedee0;
  border-radius: 3px;
}
small {
  font-size: 15px !important;
  height: auto;
}
.hr {
  display: block;
  height: 2px;
  background-color: #dedee0;
  width: 100%;
  margin: 10px 0;
}
.d-flex-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.d-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  flex-direction: column;
  width: 100%;
}
.text--accent {
  font-size: 12px;
}
.red {
  color: #ed5d30;
}
.gray {
  color: #858585;
}
.italic {
  font-style: italic;
}

.json-formatter {
  max-width: 500px !important;
  word-break: break-all;

  * {
    word-break: break-word !important;
    max-width: 500px !important;
  }

  span {
    white-space: normal !important;
  }
}

.code-block {
  font-family: monospace;
  border: 2px solid #ccc;
  background: rgba(0,0,0, 0.05);
  border-radius: 4px;
  padding: 10px;

  & + & {
    margin-top: 10px;
  }
}
</style>
